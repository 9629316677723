/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import ImageBackground from 'components/elements/ImageBackground'

// Elements
import BlockDefault from 'components/elements/BlockDefault'

// Helpers
import { getLanguage } from 'services/language'

// Icons
import Next from 'img/icon/arrow-right-circled-contrast.inline.svg'
import parse from 'html-react-parser'

const Vacatures = ({ fields }) => {
  const language = getLanguage()
  const { vacaturesNL, vacaturesEN } = useStaticQuery(graphql`
    {
      vacaturesNL: allWordpressWpVacatures(
        sort: { fields: date, order: DESC }
        filter: { wpml_current_locale: { eq: "nl_NL" } }
      ) {
        edges {
          node {
            ...VacaturesFrag
          }
        }
      }

      vacaturesEN: allWordpressWpVacatures(
        sort: { fields: date, order: DESC }
        filter: { wpml_current_locale: { eq: "en_US" } }
      ) {
        edges {
          node {
            ...VacaturesFrag
          }
        }
      }
    }

    fragment VacaturesFrag on wordpress__wp_vacatures {
      wordpress_id
      title
      path
      wpml_current_locale
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const vacatures = language === 'nl_NL' ? vacaturesNL.edges : vacaturesEN.edges

  return (
    <div className="row mt-4 mt-lg-5">
      {vacatures.map(({ node: { wordpress_id: id, path, title, acf } }) => (
        <Post key={id} path={path} title={title} acf={acf} fields={fields} />
      ))}
    </div>
  )
}

const PostBlock = styled(BlockDefault)`
  padding: 0 !important;
  overflow: hidden;
`

const PostImage = styled(ImageBackground)`
  padding-top: 60%;
`

const PostTitle = styled.h2`
  font-size: ${(props) => props.theme.font.size.xm};
  font-weight: ${(props) => props.theme.font.weight.l};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 75px;
`

const ReadMore = styled(Link)`
  font-weight: ${(props) => props.theme.font.weight.s};
  color: ${(props) => props.theme.color.text.contrast};

  & > svg {
    margin-left: 15px;

    @media (min-width: 992px) {
      height: 45px;
      width: 45px;
    }

    @media (max-width: 991px) {
      height: 35px;
      width: 35px;
    }
  }

  &:hover {
    color: ${(props) => props.theme.color.text.contrast};
    text-decoration: underline;
  }

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xm};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.m};
  }
`

const Post = ({ title, path, acf, fields }) => (
  <div className="col-sm-6 col-md-4 pb-4">
    <PostBlock>
      <PostImage src={acf.banner.image} />
      <div className="p-4">
        <PostTitle>{parse(title)}</PostTitle>

        <div className="mt-4 d-flex justify-content-end">
          <ReadMore to={path} className="d-flex align-items-center">
            {fields.read_more_title}
            <Next />
          </ReadMore>
        </div>
      </div>
    </PostBlock>
  </div>
)

export default Vacatures
