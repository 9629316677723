/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Vacatures from 'components/Vacatures'
import ParseContent from 'components/shared/ParseContent'

// Elements
import HeroAlt from 'components/elements/HeroAlt'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          description
        }

        overview {
          read_more_title
          load_more_title
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroAlt small image={acf.banner.image}>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            {parse(acf.banner.title)}
          </HeroDescription>
        </HeroAlt>
      </section>

      <section>
        <div className="container pb-5 mt-n5">
          <div className="row">
            <div className="col-lg-4" />
            <div className="col-lg-8">
              <BlockDefault className="mt-lg-n5">
                <ParseContent content={acf.meta.description} />
              </BlockDefault>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-lg-5 mb-5">
          <Vacatures fields={acf.overview} />
        </div>
      </section>
    </Layout>
  )
}

export default PageTemplate
